<template>
    <div class="android">
        <img src="../assets/android/text.png" style="width: 60vw;" />
        <van-tabs line-width="30px" title-active-color='#0D0D0D' title-inactive-color="#898989" color="#0D0D0D"
            class="custom-tabs">
            <van-tab title="华为">
                <img class="img" style="width: 88vw;" src="../assets/android/huawei.png" />
            </van-tab>
            <van-tab title="OPPO">
                <img class="img" style="width: 88vw;" src="../assets/android/oppo.png" />
            </van-tab>
            <van-tab title="VIVO">
                <img class="img" style="width: 67vw;" src="../assets/android/vivo.png" />
            </van-tab>
            <van-tab title="小米">
                <img class="img" style="width: 80vw;" src="../assets/android/mi.png" />
            </van-tab>
            <van-tab title="魅族">
                <img class="img" style="width: 65vw;" src="../assets/android/mz.png" />
            </van-tab>
        </van-tabs>
    </div>
</template>
  
<script>
export default {
    name: 'HelloWorld',
}
</script> 
<style scoped>
.android {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-tabs {
    font-size: 16px;
    font-weight: bold;
    width: 90vw;
    height: 27px;
    margin-top: 15px;
}

.custom-tabs ::v-deep .van-tabs__line {
    width: 15px !important;
    bottom: 20px;
}

.img {
    margin-top: 25px;
    margin-bottom: 25px;
}
</style>
  