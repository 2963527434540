<template>
    <div class="ios">
        <div class="webclip">
            <div style="font-size: 12px;color: #898989;">推荐同时安装防掉签</div>
            <img src="../assets/ios/sqb.png" style="width: 60px;height: 25px;margin-left: 8px;" @click="onWebClipClick" />
        </div>
        <img src="../assets/line.png" class="lineos" />
        <img src="../assets/ios/text.png" style="width: 60vw;" />
        <van-swipe class="swipe" :loop="false">
            <van-swipe-item>
                <div class="list">
                    <img class="img" src="../assets/ios/step-1.png" />
                    <img class="img" src="../assets/ios/step-2.png" />
                    <img class="img" src="../assets/ios/step-3.png" />
                    <img class="img" src="../assets/ios/step-4.png" />
                </div>
            </van-swipe-item>
        </van-swipe>
    </div>
</template>
  
<script>


export default {
    name: 'IosVue',
    props: {
        showWebclip: Function
    },
    setup(props) {
        const onWebClipClick = () => {
            if (props.showWebclip) {
                props.showWebclip();
            }
        }
        return { onWebClipClick }
    }
}
</script> 
<style scoped>
.ios {
    display: flex;
    flex-direction: column;
    align-items: center;

    .swipe {
        width: 100vw;
        margin-top: 25px;
        margin-bottom: 25px;

        .list {
            flex-direction: row;
            display: flex;
            align-items: center;
            overflow-x: auto;
            padding-left: 10px;
        }

        .img {
            width: 260px;
            margin-left: 10px;
        }
    }
}

.webclip {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lineos {
    width: 90%;
    margin-top: 15px;
    margin-bottom: 15px;
}
</style>
  