<template>
    <div class="ios">
        <div class="title">
            <img class="backarrow" src="../assets/fanhui.png" @click="onExitClick" />
            <span>安装书签版</span>
        </div>
        <img class="weblogo" src="../assets/logo.png" />
        <div class="appname">乐球直播</div>
        <div class="webinfo">防掉签书签版安装后，可防止网址丢失，若“乐球直播” <br />APP掉签无法打开时可在书签版快捷访问。</div>
        <div class="downloadbutton" @click="onWebclipDown">下载书签版</div>
        <div class="areadydown">已下载，安装书签版描述文件</div>
        <img class="webclipstep" src="../assets/webclip/step.png" />
    </div>
</template>
  
<script>
export default {
    name: 'WebclipVue',
    props: {
        exit: Function,
        download: Function
    },
    setup(props) {
        const onExitClick = () => {
            if (props.exit) {
                props.exit();
            }
        }
        const onWebclipDown = () => {
            if (props.download) {
                props.download();
            }
        }
        return { onExitClick, onWebclipDown }
    }
}
</script> 
<style scoped>
.ios {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backarrow {
    position: absolute;
    left: 0px;
    margin-left: 12px;
    width: 15px;
}

.title {
    font-size: 17px;
    font-weight: bold;
    margin-top: 30px;
    color: #0D0D0D;
    align-items: center;
    display: flex;
}

.weblogo {
    width: 113px;
    height: 113px;
    margin-top: 25px;
}

.appname {
    font-size: 20px;
    font-weight: bold;
    color: #0D0D0D;
    margin-top: 12px;
}

.webinfo {
    font-size: 12px;
    color: #0D0D0D;
    margin-top: 12px;
}

.downloadbutton {
    width: calc(100vw - 25px);
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #91EF11;
    border-radius: 22px;
    margin-top: 25px;
    color: #0D0D0D;
    font-weight: bold;
}

.areadydown {
    font-size: 12px;
    color: #3E7BED;
    margin-top: 10px;
}

.webclipstep {
    width: calc(100vw - 14px);
    margin-bottom: 25px;
    margin-top: 25px;
}
</style>
  