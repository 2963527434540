import { createApp } from 'vue'
import App from './App.vue'
import { Tabs, Tab, Swipe, SwipeItem, List, Overlay, Toast, Dialog } from 'vant'
import 'vant/lib/index.css'; // 引入 Vant 样式


let app = createApp(App);
app.use(Tabs)
    .use(Tab)
    .use(Swipe)
    .use(SwipeItem)
    .use(List)
    .use(Overlay)
    .use(Toast)
    .use(Dialog)
    .mount('#app');
